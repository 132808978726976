import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProtectImage from "../../static/images/Protect.png";
import MarketImage from "../../static/images/Market.png";
import ProductivityImage from "../../static/images/Increase-Productivity.svg";
import GeolocationImage from "../../static/images/GeoLocation.png";

const AboutPage = () => (
    <Layout>
        <SEO title="About"/>

        <div className={"page-header"}>
            <h2>Features</h2>
            <p></p>
        </div>

        <div className={"container"}>
            <div className={"targets"}>
                <div className={"title"}>
                    <h2>About Us</h2>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        <div className={"item"}>
                            <img alt={"Protect"} src={ProtectImage}/>
                            <h3>Protecting business brands</h3>
                            <p>Fake items can be costly to business. We aim to help businesses and startups protect their brands.</p>
                        </div>
                    </div>

                    <div className={"col-6"}>
                        <div className={"item"}>
                            <img alt={"Event"} src={MarketImage}/>
                            <h3>Engage the markets</h3>
                            <p>We aim to help business gets better understanding of their market.</p>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        <div className={"item"}>
                            <img alt={"Productivity"} src={ProductivityImage}/>
                            <h3>Keep Track of your product</h3>
                            <p>We provide latest tools to help you trace your products and verify the history and location of your products. Right from raw materials to the last mile, you can get all the information you need!</p>
                        </div>
                    </div>

                    <div className={"col-6"}>
                        <div className={"item"}>
                            <img alt={"Geolocation"} src={GeolocationImage}/>
                            <h3>Geolocation</h3>
                            <p>The Geolocation tracking makes it easy for business to know the actual product location as well as the consumer location when scanned.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default AboutPage
